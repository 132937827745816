export const emailValidation = (email: string) => {
    if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email,
        )
    ) {
        return true;
    }
    if (email.trim() === '') {
        return 'Email is required';
    }
    return 'Please enter a valid email';
};

export const nameValidation = (name: string) => {
    if (name.length > 30 || name.length < 5) {
        return "name must be between 5 and 30 characters long";
    }

    return true
}


// super strong regex from : https://www.ocpsoft.org/tutorials/regular-expressions/password-regular-expression/

// ^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]).{8,32}$
// export const passwordValidation = (password: string) => {
//     if (
//         /^[a-z].{5,10}*$/.test(
//             password,
//         )
//     ) {
//         return true;
//     }
//     if (password.trim() === '') {
//         return 'Email is required';
//     }
//     return 'Please enter a valid email';
// }