import { createSlice } from "@reduxjs/toolkit";
import { ICard, IImage } from "../Types/card";
import { AddCard, fetchCards, UpdateCard } from "./cardsMiddleware";

import { RootState } from "./store";

type CardsState = {
    list: ICard[];
    currentIndex: number;
};
const defaultImage: IImage = {
    preview: "",
    raw: null,
};
const defaultCard: ICard = {
    Image: defaultImage,
    UserId: "",
    ProductName: `${
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 9)
    }`,
    Type: "",
    Gender: "",
    Price: "",
    Sizes: [],
};
const initialState = {
    list: [defaultCard],
    currentIndex: 0,
} as CardsState;

export const cardsSlice = createSlice({
    name: "cards",
    initialState,
    reducers: {
        nextCard(state) {
            state.currentIndex++;
        },
        previousCard(state) {
            state.currentIndex--;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(AddCard.fulfilled, (state, { payload }) => {
            state.list.shift();
            state.list.unshift(payload);
            state.list.unshift(defaultCard);
            state.currentIndex++;
        });

        builder.addCase(fetchCards.fulfilled, (state, { payload }) => {
            payload.forEach((card) => {
                let found = state.list.find((element) => element._id === card._id);
                if (!found) {
                    state.list.push(card);
                }
            });
        });

        builder.addCase(UpdateCard.fulfilled, (state, { payload }) => {
            state.list.forEach((element,index) => {
                if (element._id === payload._id) {
                    console.log("Found Card to update ", payload.Price)
                    state.list[index] = payload;
                    return;
                }
            });
        });
    },
});

export default cardsSlice.reducer;
export const { nextCard, previousCard } = cardsSlice.actions;
export const GetCards = (state: RootState) => state.cards.list;
export const GetActiveCard = (state: RootState) => state.cards.list[state.cards.currentIndex];
export const GetCurrentIndex = (state: RootState) => state.cards.currentIndex;
