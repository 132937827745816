import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Usercontext } from "../../../App";
import * as ROUTES from "./../../../constants/routes";
import "./../SignUp/SignUp.scss";
import loading from "./../../../assets/circles.svg";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import { addNotification } from "../../../redux/notificationsSlice";
import { ENotificationType } from "../../../Types/notification";
interface SignInUser {
    email: string;
    password: string;
}
const initialState: SignInUser = {
    email: "",
    password: "",
};

export const SignIn = () => {
    const [user, setUser] = useState<SignInUser>(initialState);
    const [submitted, setSubmitted] = useState(false);


    const firebase = useContext(Usercontext);

    const history = useHistory();
    const dispatch=useDispatch();

    const onSubmit = (e: React.SyntheticEvent) => {
        setSubmitted(true)
        e.preventDefault();
        console.log("login ");
        firebase
            .auth()
            .signInWithEmailAndPassword(user.email, user.password)
            .then(() => {
                history.push(ROUTES.HOME);
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        message: `Failed to sign up.`,
                        id: v4(),
                        type: ENotificationType.Failure,
                    })
                );
                setUser(initialState)
                setSubmitted(false)
            });
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget;
        setUser({ ...user, [name]: value });
    };
    return (
        <div className="signUpContainer" onSubmit={onSubmit}>
            {submitted ? (
                <img src={loading} alt="loading..." className="loading" />
            ) : (
                <form className="signUpForm">
                    <h4> Sign in</h4>
                    <div className={`inputContainer`}>
                        <label className="formLabel">Email Address</label>
                        <input
                            className={`formField`}
                            name="email"
                            value={user.email}
                            onChange={(e) => onChange(e)}
                            type="text"
                            required
                        />
                    </div>
                    <div className={`inputContainer`}>
                        <label className="formLabel">Password</label>
                        <input
                            className={`formField`}
                            name="password"
                            value={user.password}
                            onChange={(e) => onChange(e)}
                            type="password"
                            required
                        />
                    </div>

                    <div className={`signButtonContainer`}>
                        <button type="submit">Sign In</button>
                    </div>
                </form>
            )}
        </div>
    );
};
