import React, { useRef } from 'react'
import { IImage } from '../../../Types/card';
import "./ImageDrop.css"

interface ImageProps {
    imageState: [IImage, React.Dispatch<React.SetStateAction<IImage>>]
}
export const ImageDrop: React.FC<ImageProps> = ({ imageState: [image, setImage] }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const imageUploaded = (fileList: FileList | null) => {
        if (fileList == null) {
            return
        }
        if (fileList.length > 0) {
            setImage(prevState => {
                let newState = Object.assign({}, prevState);
                newState.raw = fileList[0];
                newState.preview = URL.createObjectURL(fileList[0]);

                return newState;
            })
        }
    }

    return (
        <div onClick={() => inputRef.current?.click()} className="imageDropContainer">
            <img alt="upload" src={image.preview} />
            <input
                ref={inputRef}
                className="hiddenInput"
                type="file"
                accept="image/png, image/jpeg"
                onChange={e => imageUploaded(e.target.files)}
            />

        </div>
    )
}
