import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Usercontext } from "../../../App";
import { fetchCards } from "../../../redux/cardsMiddleware";
import { GetCards, GetCurrentIndex, nextCard, previousCard } from "../../../redux/cardsSlice";

import { Card } from "../Card/Card";
import * as ROUTES from "./../../../constants/routes";
import "./Home.css";

import back from "./../../../assets/chevron-back.png";
import forward from "./../../../assets/chevron-forward.png";
import { useTypedSelector } from "../../../redux/store";
import { v4 } from "uuid";
import { addNotification } from "../../../redux/notificationsSlice";
import { ENotificationType } from "../../../Types/notification";

export const Home = () => {
    const firebase = useContext(Usercontext);
    const [signedIn, setSignedIn] = useState(false);

    const dispatch = useDispatch();

    const history = useHistory();

    const currentIndex = useTypedSelector(GetCurrentIndex);
    const cardsList = useTypedSelector(GetCards);

    const Signout = () => {
        firebase.auth().signOut();
    };

    useEffect(() => {
        console.log("Cards List ", cardsList);
    }, [cardsList]);



    const GetMoreCards= useCallback((number: number, currentIndex: number)=>{
        if (signedIn) {
            firebase
                .auth()
                .currentUser?.getIdToken()
                .then((token) => {
                    dispatch(
                        fetchCards({
                            bearerToken: token,
                            number: number,
                            currentIndex: currentIndex,
                        })
                    );
                });
        }

    },[dispatch, firebase, signedIn])

    useEffect(() => {
        GetMoreCards(5,0)
    }, [GetMoreCards]);

    useEffect(() => {
        const unlisten = firebase.auth().onAuthStateChanged((user) => {
            if (user === null) {
                setSignedIn(false);
                history.push(ROUTES.SIGN_IN);
            } else {
                setSignedIn(true);
            }
        });
        return () => {
            unlisten();
        };
    }, [firebase, history]);
    const nextClicked = (e: any) => {
        if(currentIndex===cardsList.length-2){  
            dispatch(
                addNotification({
                    message: `Getting more cards`,
                    id: v4(),
                    type: ENotificationType.Success,
                })
            );
            GetMoreCards(5,currentIndex+1)
            dispatch(nextCard());

        }else if (currentIndex === cardsList.length-1 ) {
            dispatch(
                addNotification({
                    message: `No next card available`,
                    id: v4(),
                    type: ENotificationType.Failure,
                })
            );
        } else {
            dispatch(nextCard());
        }
    };
    const previousClicked = (e: any) => {
        if (currentIndex === 0) {
            dispatch(
                addNotification({
                    message: `No previous card available`,
                    id: v4(),
                    type: ENotificationType.Failure,
                })
            );
        } else {
            dispatch(previousCard());
        }
    };
    return (
        <div>
            {!signedIn ? (
                <div> redirect</div>
            ) : (
                <div className="homeContainer">
                    <div className="leftbar"> you got home</div>
                    <div className="mainCard">
                        <div className="previous">
                            <img
                                src={back}
                                alt="back"
                                className={`navigationButton`}
                                onClick={previousClicked}
                            />
                        </div>
                        <div className="card">
                            <Card />
                        </div>
                        <div className="next">
                            <img
                                src={forward}
                                alt="next"
                                className={`navigationButton`}
                                onClick={nextClicked}></img>
                        </div>
                    </div>

                    <div className="rightbar"> other side of card</div>
                    <div className="footer">footer</div>
                </div>
            )}
        </div>
    );
};
