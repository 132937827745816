import React from "react";
import { ICardMetaData} from "../../../Types/card";
import "./CardFormData.scss";

interface ISize {
    key: number;
    label: string;
}
const sizes: ISize[] = [
    { key: 0, label: "XSS" },
    { key: 1, label: "XS" },
    { key: 2, label: "S" },
    { key: 3, label: "M" },
    { key: 4, label: "L" },
    { key: 5, label: "XL" },
    { key: 6, label: "XXL" },
];

const checkIfStringInArray = (label: string, array: string[]): string[] => {
    let newArray = [...array];
    if (array.includes(label)) {
        return newArray.filter((el) => el !== label);
    }
    newArray.push(label);
    return newArray;
};


interface CardMetaDataProps {
    metaDataState: [
        ICardMetaData,
        React.Dispatch<React.SetStateAction<ICardMetaData>>
    ];
}

export const CardMetaDataEdit: React.FC<CardMetaDataProps> = ({
    metaDataState: [metaData, setMetaData]
}) => {

    const onChange = (e: any) => {
        const { name, value } = e.currentTarget;
        setMetaData({ ...metaData, [name]: value });
    };

    const onCheckboxChanged = (e: any) => {
        const { name } = e.currentTarget;
        setMetaData((prevState) => ({
            ...prevState,
            sizes: checkIfStringInArray(name, prevState.sizes),
        }));
    };


    return (
        <form className={`dataEditForm`}>
            <div className="tooltiptext"></div>
            <div>
            <label className="form__label">Gender</label>
                <input
                    name="gender"
                    value={metaData.gender}
                    onChange={onChange}
                    type="text"
                    className="form__field"
                />

            </div>
            <div>
            <label className="form__label">Price</label>
                <input
                    name="price"
                    value={metaData.price}
                    onChange={onChange}
                    type="text"
                    className="form__field"
                />
            </div>
            <div>
            <label className="form__label">Type</label>
                <input
                    name="type"
                    value={metaData.type}
                    onChange={onChange}
                    type="text"
                    className="form__field"
                />
            </div>
            <div>
            <label className="form__label">Product Name</label>
                <input
                    name="productName"
                    value={metaData.productName}
                    onChange={onChange}
                    type="text"
                    className="form__field"
                />
            </div>


            <div className="checkboxContainer">
                {sizes.map((size) => {
                    return (
                        <div key={size.key} className="individualCheckbox">
                            <input
                                type="checkbox"
                                name={size.label}
                                onChange={onCheckboxChanged}
                                checked={metaData.sizes.includes(size.label)}
                            />
                            <label>{size.label}</label>
                        </div>
                    );
                })}
            </div>
        </form>
    );
};
