import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeNotification } from "../../../redux/notificationsSlice";
import { INotification } from "../../../Types/notification";
import "./Notification.css";
interface NotificationProps {
    notification: INotification;
}

//Sinlge notification Component
export const Notification: React.FC<NotificationProps> = ({ notification }) => {
    const dispatch = useDispatch();
    //width of the progress bar
    const [width, setWidth] = useState<number>(0);

    // used to slide bar out of view when width is 100
    const [exit, setExit] = useState(false);

    // used to store the interval for the progress bar
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

    //starting the interval timer
    const startTimer = () => {
        // id of the interval that we then  store in state
        const id = setInterval(() => {
            // increment the width by 0.5 every 20 ms
            setWidth((prevState) => {
                if (prevState < 100) {
                    return prevState + 0.5;
                }
                clearInterval(id);
                return prevState;
            });
        }, 20);
        setIntervalId(id);
    };

    const handleCloseNotification = () => {
        setExit(true);
        pauseTimer();
        setTimeout(() => {
           dispatch( removeNotification(notification));
        }, 500);
    };
    // for mouse hover over
    const pauseTimer = () => {
        clearInterval(intervalId!);
    }; 

    // when component mounts start the time for the progress bar
    useEffect(() => {
        startTimer();
    }, []);

    //when the width of the progress bar hits 100 start the removal process
    useEffect(() => {
        if (width === 100) {
            handleCloseNotification();
        }
    }, [width]);

    return (
        <div
            onMouseEnter={pauseTimer}
            onMouseLeave={startTimer}
            className={`notificationContainer ${exit ? "exit" : ""}`}
        >
            <p> {notification.message}</p>
            <div
                className={`bar ${notification.type}`}
                style={{ width: `${width}%` }}
            ></div>
        </div>
    );
};
