import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICard, IResponseCard, ISendCard, ISendFetchCards } from "../Types/card";

const BASEURL=process.env.REACT_APP_SERVER_URL


export const AddCard = createAsyncThunk<ICard, ISendCard>(
    "cards/AddCard",
    async (card: ISendCard) => {
        var formData = new FormData();
        if (card.Image.raw !== null) {
            formData.append("image", card.Image.raw, card.Image.raw.name);
        }
        formData.append("productName", card.ProductName);
        formData.append("gender", card.Gender);
        formData.append("sizes", JSON.stringify(card.Sizes));
        formData.append("type", card.Type);
        formData.append("price", card.Price);
        const response = await fetch(`${BASEURL}/addCard`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${card.bearerToken}`,
            },
            body: formData,
        });

        const data: IResponseCard = await response.json();

        const formatted: ICard = { ...data, Image: { preview: data.ImageUrl, raw: null } };

        return formatted;
    }
);

export const UpdateCard = createAsyncThunk<ICard, ISendCard>(
    "cards/UpdateCard",
    async (card: ISendCard) => {
        var formData = new FormData();
        if (card.Image.raw !== null) {
            formData.append("image", card.Image.raw, card.Image.raw.name);
        }else{
            formData.append("imageUrl", card.Image.preview);
        }
        formData.append("productName", card.ProductName);
        formData.append("gender", card.Gender);
        formData.append("sizes", JSON.stringify(card.Sizes));
        formData.append("type", card.Type);
        formData.append("price", card.Price);
        console.log("Updated card action dispatched", card)
        const response = await fetch(`${BASEURL}/${card._id}/updateCard`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${card.bearerToken}`,
            },
            body: formData,
        });

        const data: IResponseCard = await response.json();
        console.log("Got update response from backend", data)
        const formatted: ICard = { ...data, Image: { preview: data.ImageUrl, raw: null } };
        return formatted;
    }
);

export const fetchCards = createAsyncThunk<ICard[], ISendFetchCards>(
    "cards/fetch",
    async (req: ISendFetchCards) => {
        const response = await fetch(`${BASEURL}/${req.number}/fetch`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${req.bearerToken}`,
                "currentIndex": `${req.currentIndex}`,
            },
        });
        const data: IResponseCard[] = await response.json();
        const formatted: ICard[] = data.map((card) => ({
            ...card,
            Image: { preview: card.ImageUrl, raw: null },
        }));
        return formatted;
    }
);
