import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Usercontext } from "../../../App";
import { Person } from "../../../Types/user";
import { nameValidation } from "../Validation/Validation";
import * as ROUTES from "./../../../constants/routes";

import "./SignUp.scss";

import loading from "./../../../assets/circles.svg";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../redux/notificationsSlice";
import { v4 } from "uuid";
import { ENotificationType } from "../../../Types/notification";
const BASEURL = process.env.REACT_APP_SERVER_URL;
interface SignUpUser {
    name: string;
    email: string;
    passwordOne: string;
    passwordTwo: string;
}


const initialState: SignUpUser = {
    name: "",
    email: "",
    passwordOne: "",
    passwordTwo: "",
};
export const SignUp = () => {
    const [user, setUser] = useState<SignUpUser>(initialState);
    const [submitted, setSubmitted] = useState(false);

    const firebase = useContext(Usercontext);

    const history = useHistory();
    const dispatch=useDispatch();

    const setName = (name: string) => {
        setUser((prevState) => {
            let validationResult = nameValidation(name);
            if (validationResult) {
            }
            let newState = Object.assign({}, prevState);
            newState.name = name;
            return newState;
        });
    };

    const setEmail = (email: string) => {
        setUser((prevState) => {
            let newState = Object.assign({}, prevState);
            newState.email = email;
            return newState;
        });
    };

    const setPasswordOne = (passwordOne: string) => {
        setUser((prevState) => {
            let newState = Object.assign({}, prevState);
            newState.passwordOne = passwordOne;
            return newState;
        });
    };
    const setPasswordTwo = (passwordTwo: string) => {
        setUser((prevState) => {
            let newState = Object.assign({}, prevState);
            newState.passwordTwo = passwordTwo;
            return newState;
        });
    };
    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setSubmitted(true)
        firebase
            .auth()
            .createUserWithEmailAndPassword(user.email, user.passwordOne)
            .then((res) => {
                firebase
                    .auth()
                    .currentUser?.getIdToken()
                    .then((token) => {
                        console.log("Got token", token);
                        registerWithBackend(token);
                    });
            });
    };

    const registerWithBackend = (token: string) => {
        let person: Person = {
            FirstName: user.name,
            Surname: user.name,
            Email: user.email,
        };
        let url = `${BASEURL}/adduser`;
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(person),
        }).then((response) => {
            if (response.status === 202) history.push(ROUTES.HOME);
        }).catch(error=>{
            dispatch(
                addNotification({
                    message: `Failed to sign up.`,
                    id: v4(),
                    type: ENotificationType.Failure,
                })
            );
            setUser(initialState)
        })
    };
    return (
        <div className="signUpContainer">
            {submitted ? (
                <img src={loading} alt="loading..." className="loading"/>
            ) : (
                <form onSubmit={onSubmit} className={`signUpForm`}>
                    <h4> Sign up </h4>
                    <div className={`inputContainer`}>
                        <label className="formLabel">Full Name</label>
                        <input
                            name="username"
                            value={user.name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            className="formField"
                            required
                            autoComplete="off"
                            
                        />
                    </div>

                    <div className={`inputContainer`}>
                        <label className="formLabel">Email Address</label>
                        <input
                            name="email"
                            value={user.email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            className={`formField`}
                            required
                            autoComplete="off"
                        />
                    </div>

                    <div className={`inputContainer`}>
                        <label className="formLabel">Password</label>
                        <input
                            name="passwordOne"
                            value={user.passwordOne}
                            onChange={(e) => setPasswordOne(e.target.value)}
                            type="password"
                            className="formField"
                            required
                            autoComplete="off"
                        />
                    </div>

                    <div className={`inputContainer`}>
                        <label className="formLabel">Confirm Password</label>
                        <input
                            name="passwordTwo"
                            value={user.passwordTwo}
                            onChange={(e) => setPasswordTwo(e.target.value)}
                            type="password"
                            className="formField"
                            required
                            autoComplete="off"
                        />
                    </div>
                    <div className={`signButtonContainer`}>
                        <button type="submit">Sign Up</button>
                    </div>
                </form>
            )}
        </div>
    );
};
