import React from "react";
import { ICardMetaData } from "../../../Types/card";
import "./CardFormData.scss";

interface CardMetaDataProps {
    metadata: ICardMetaData;
}
export const CardMetaDataView: React.FC<CardMetaDataProps> = (metadata) => {
    const renderSizes = (sizes: string[]) => {
        if (sizes.length === 0) {
            return "No sizes availables.";
        }
        return sizes.map((value, index) => {
            if (index === sizes.length - 1) {
                return value;
            }
            return value + ", ";
        });
    };
    return (
        <div>
            <ul className="form__viewList">
                {Object.entries(metadata.metadata).map(([key, value], index) =>
                    key !== "sizes" ? (
                        <div key={index} className="form__viewItem">
                            <label className="form__label"> {capitalizeFirstLetter(key)}</label>
                            <p className="form__display"> {value}</p>
                        </div>
                    ) : (
                        <div key={index} className="form__viewItem">
                            <label className="form__label"> {capitalizeFirstLetter(key)}</label>
                            <p className="form__display">{renderSizes(value)}</p>
                        </div>
                    )
                )}
            </ul>
        </div>
    );
};

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
