import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Usercontext } from '../../../App';
import { NavigationItems, SingleNavigationItem } from './NavigationItem';
import "./Navigation.css"



const GetNavItems=(signedIn:boolean): SingleNavigationItem[]=>{
    
    if(signedIn){
        return NavigationItems.filter(item=> item.needsAuthentication|| item.title==="Landing")
    }
    else{
        return NavigationItems.filter(item=> !item.needsAuthentication|| item.title==="Landing")
    }

    
}
export const NavigationBar = () => {
    const firebase = useContext(Usercontext);
    const [signedIn, setSignedIn] = useState(false);
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    useEffect(() => {
        const unlisten = firebase.auth().onAuthStateChanged((user) => {
            if (user === null) {
                setSignedIn(false);
            } else {
                setSignedIn(true);
            }
        });
        return () => {
            unlisten();
        };
    }, [firebase]);
    const itemClicked=(item:SingleNavigationItem)=>{
        setClick(false)
        if(item.title==="Signout"){
            firebase.auth().signOut();
        }
    }

    return (
        <nav className="navbar">
            <h1 className="navbar-logo"> Chemistry <i className="fab fa-react"></i></h1>
            <div className="menu-icon" onClick={handleClick}> 
            <i className={click? 'fas fa-times': 'fas fa-bars'}></i>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                {GetNavItems(signedIn).map((item,index)=>(
                    <li key={index} >
                        <Link to={item.path} className={item.className} onClick={e=>itemClicked(item)} > {item.title}</Link>
                    </li>
                ))}
            </ul>
        </nav>
    )
}
