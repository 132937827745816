export interface INotification {
    id: string,
    type: ENotificationType,
    message: string
}

export enum ENotificationType {
    Success ="success",
    Failure="failure",
    Information="information"
}