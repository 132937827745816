import { configureStore } from "@reduxjs/toolkit";
// Import our reducer from the sluce:
import { 
  TypedUseSelectorHook, 
  useSelector 
} from "react-redux";

import cardsReducer from "./cardsSlice";
import notificationsReducer from "./notificationsSlice"

export const store = configureStore({
  reducer: {
    cards: cardsReducer,
    notifications:notificationsReducer
  },
});


export type RootState = ReturnType<typeof store.getState>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;