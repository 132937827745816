import * as ROUTES from "./../../../constants/routes";

 export interface SingleNavigationItem{
     className: string,
     title: string,
     path: string,
     needsAuthentication: boolean,
     onlyNoAuth: boolean
 }


export const NavigationItems:SingleNavigationItem[]=[
    {
        className:"nav-links",
        title:"Home",
        path: ROUTES.HOME,
        needsAuthentication: true,
        onlyNoAuth:false
    },
    {
        className:"nav-links",
        title:"Admin",
        path: ROUTES.ADMIN,
        needsAuthentication: true,
        onlyNoAuth:false
    },
    {
        className:"nav-links",
        title:"Landing",
        path: ROUTES.LANDING,
        needsAuthentication: false,
        onlyNoAuth: false
    },
    {
        className:"nav-links",
        title:"SignIn",
        path: ROUTES.SIGN_IN,
        needsAuthentication: false,
        onlyNoAuth:true
    },
    {
        className:"nav-links",
        title:"SignUp",
        path: ROUTES.SIGN_UP,
        needsAuthentication: false,
        onlyNoAuth: true
    },
    {
        className:"nav-links",
        title:"Signout",
        path: ROUTES.LANDING,
        needsAuthentication: true,
        onlyNoAuth: false
    },

] 