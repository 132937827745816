import React from "react";

export const Landing = () => {
    return (
        <div>
            <h1> Welcome to Chemistry Management</h1>
            <div>
                <h2> About us</h2>
                <div>
                    <div>
                        <h3> Chemistry mobile application</h3>

                        <p>
                            Gamified tinder styled fashion app, users will be able to swipe on
                            various clothing item from different companies, swiping "right" on a
                            card will give the user a chance to either win a configurable discount
                            on the piece, or get the entire item for free!
                        </p>
                    </div>
                    <div>
                        <h3> Chemistry Mangement site </h3>

                        <p>
                            Use this site to manage your cards on our mobile application available
                            on the PlayStore and the Appstore. Upload new items of clothing as cards
                            that will users will be able to swipe left or right in their app.
                            Swiping right on the cards will give them a chance to win the item of
                            clothing.
                        </p>
                        <p>
                            More functionality coming including advanced card metrics...
                        </p>
                    </div>
                </div>
                <h2> Get in Contact</h2>
                <div>
                    
                </div>
            </div>
        </div>
    );
};
