import { useContext, useEffect, useState } from "react";
import { ICard, ICardMetaData, IImage } from "../../../Types/card";
import { ImageDrop } from "../ImageDrop/ImageDrop";
import "./Card.css";
import { CardMetaDataEdit } from "../CardMetaData/CardFormDataEdit";
import { CardMetaDataView } from "../CardMetaData/CardMetaDataView";
import { addNotification } from "../../../redux/notificationsSlice";
import { v4 } from "uuid";
import { ENotificationType } from "../../../Types/notification";
import { useDispatch } from "react-redux";
import { AddCard, UpdateCard } from "../../../redux/cardsMiddleware";
import { Usercontext } from "../../../App";
import { useTypedSelector } from "../../../redux/store";
import { GetActiveCard } from "../../../redux/cardsSlice";

const defaultImage: IImage = {
    preview: "",
    raw: null,
};

const defaultFormData: ICardMetaData = {
    gender: "g",
    price: "g",
    type: "g",
    productName: `${
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 9)
    }`,
    sizes: [],
    edited: true,
};

export const Card = () => {
    const [image, setImage] = useState<IImage>(defaultImage);
    const [metaData, setMetaData] = useState<ICardMetaData>(defaultFormData);
    const [edit, setEdit] = useState(true);
    const firebase = useContext(Usercontext);
    const dispatch = useDispatch();

    const card: ICard = useTypedSelector(GetActiveCard);
    useEffect(() => {
        if (card.Image.preview !== "") {
            setEdit(false);
        } else {
            setEdit(true);
        }
        let newMetaData: ICardMetaData = {
            gender: card.Gender,
            price: card.Price,
            productName: card.ProductName,
            sizes: card.Sizes,
            type: card.Type,
            edited: false,
        };

        setImage(card.Image);
        setMetaData(newMetaData);
    }, [card]);

    const onSubmit = () => {
        var isMetaDataValid = validateMetadata();
        var isImageValid = validateImage();
        if (isImageValid && isMetaDataValid) {
            firebase
                .auth()
                .currentUser?.getIdToken()
                .then((token) => {
                    if (card._id) {
                        dispatch(
                            UpdateCard({
                                Image: image,
                                UserId: firebase.auth().currentUser?.uid!,
                                ProductName: metaData.productName,
                                Type: metaData.type,
                                Gender: metaData.gender,
                                Price: metaData.price,
                                Sizes: metaData.sizes,
                                bearerToken: token,
                                _id: card._id,
                            })
                        );
                    } else {
                        dispatch(
                            AddCard({
                                Image: image,
                                UserId: firebase.auth().currentUser?.uid!,
                                ProductName: metaData.productName,
                                Type: metaData.type,
                                Gender: metaData.gender,
                                Price: metaData.price,
                                Sizes: metaData.sizes,
                                bearerToken: token,
                            })
                        );
                    }
                });
        }
    };
    const validateImage = (): boolean => {
        if (image.preview === "") {
            dispatch(
                addNotification({
                    message: `Image not added.`,
                    id: v4(),
                    type: ENotificationType.Failure,
                })
            );
        }
        return image.preview !== "";
    };
    const validateMetadata = () => {
        let validationResult = Object.entries(metaData).map(([key, value], index) => {
            if (value === "") {
                dispatch(
                    addNotification({
                        message: `${key} must not be empty.`,
                        id: v4(),
                        type: ENotificationType.Failure,
                    })
                );
                return key;
            }
            return "";
        });
        let filtered = validationResult.filter((x) => x !== "");
        return filtered.length === 0;
    };
    return (
        <div className="cardContainer">
            <div className="image">
                <ImageDrop imageState={[image, setImage]}></ImageDrop>
            </div>

            <div className="title">{metaData.productName}</div>
            {edit ? (
                <div className="metadata">
                    <CardMetaDataEdit metaDataState={[metaData, setMetaData]} />
                    <input
                        type="submit"
                        value="Submit"
                        disabled={false}
                        className="submitMetadata"
                        onClick={onSubmit}
                    />
                </div>
            ) : (
                <div className="metadata">
                    <CardMetaDataView metadata={metaData} />
                    <input type="submit" value="Edit" onClick={() => setEdit(true)} />
                </div>
            )}
        </div>
    );
};
