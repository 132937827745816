import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 } from "uuid";
import { ENotificationType, INotification } from "../Types/notification";
import { AddCard } from "./cardsMiddleware";

import { RootState } from "./store";

type NotificationState = {
    notificationList: INotification[];
};
const initialState = {
    notificationList: [],
} as NotificationState;

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        addNotification(state, action: PayloadAction<INotification>) {
            state.notificationList.push(action.payload);
        },
        removeNotification(state, action: PayloadAction<INotification>) {
            state.notificationList = state.notificationList.filter(
                (notification) => notification.id !== action.payload.id
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(AddCard.fulfilled, (state, { payload }) => {

            let successCard:INotification={
                message:` Successfully added card ${payload.ProductName}`,
                id:v4(),
                type: ENotificationType.Success
            }
            state.notificationList.push(successCard)
        });
    },
});

export default notificationsSlice.reducer;
export const { addNotification, removeNotification } = notificationsSlice.actions;

export const getNotifications = (state: RootState) => state.notifications.notificationList;
