import { useEffect, useState } from "react";

import "./App.css";
import { createContext } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SignIn } from "./app/components/SignIn/SignIn";
import * as ROUTES from "./constants/routes";
import { SignUp } from "./app/components/SignUp/SignUp";
import { Home } from "./app/components/Home/Home";
import { NavigationBar } from "./app/components/NavigationBar/NavigationBar";
import { Landing } from "./app/components/Landing/Landing";

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

// react-redux-firebase config
const rrfConfig = {
    userProfile: "users",
};

// Initialize firebase instance
var fire = firebase.initializeApp(config);

export const Usercontext = createContext(fire);

export const useFirebase = () => {
    const [authUser, setAuthUser] = useState<firebase.User | null>(firebase.auth().currentUser);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => setAuthUser(user));
        return () => {
            unsubscribe();
        };
    }, []);
};
function App() {
    return (
        <Router>
            <div>
                <Usercontext.Provider value={fire}>
                    <NavigationBar />
                    <Switch>
                        <Route path={ROUTES.LANDING} exact component={Landing} />
                        <Route path={ROUTES.SIGN_UP} component={SignUp} />
                        <Route path={ROUTES.SIGN_IN} component={SignIn} />
                        <Route path={ROUTES.HOME} component={Home} />
                    </Switch>
                </Usercontext.Provider>
            </div>
        </Router>
    );
}

export default App;
