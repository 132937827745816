import  { useEffect } from 'react'
import { getNotifications } from '../../../redux/notificationsSlice'
import { useTypedSelector } from '../../../redux/store'
import { Notification } from './Notification'
import "./Notification.css"


export const NotificationWrapper = () => {
    const notifications=  useTypedSelector(getNotifications)

    useEffect(() => {
    }, [notifications])
    return (
        <div className={`notificationWrapperContainer`}>
            {notifications.map((notification) => {
                return (
                    <div key={notification.id }>
                        <Notification notification={notification} />

                    </div>
                )
            })}
        </div>
    )
}
